import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    isAuthorized: false,
    isGuest: false,
    user: {}
  },
  getters: {
    getAuthorize: (state: any) => state.isAuthorized && !state.isGuest,
    getUser: (state: any) => state.user,
    getGuest: (state: any) => state.isGuest
  },
  mutations: {
    SET_AUTHORIZE: (state: any) => {
      state.isAuthorized = true;
      state.user = {};
    },
    SET_UNAUTHORIZE: (state: any) => {
      state.isAuthorized = false;
    },
    SET_USER: (state: any, data: any) => {
      console.log({ data });
      state.isGuest = data.isGuest;
      state.user = data;
    },
    SET_GUEST: (state: any, bool: boolean) => {
      if (bool) {
        localStorage.setItem("isGuest", String(bool));
      } else {
        localStorage.removeItem("isGuest");
      }
      state.isGuest = bool;
    }
  },
  actions: {
    LOGIN_USER: (
      { dispatch, commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("login", data)
          .then(({ data }) => {
            commit("SET_AUTHORIZE");
            localStorage.setItem("logged", "true");
            localStorage.setItem("token", data.data.access_token);
            dispatch("profile/GET_PROFILE", null, { root: true }).then(
              ({ type }: { type: string }) => {
                localStorage.setItem("userType", type);
                resolve(true);
              }
            );
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    REGISTER_GUEST_USER: (
      { dispatch }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("register-guest", data)
          .then(({ data }) => {
            // commit("SET_AUTHORIZE");
            localStorage.setItem("logged", "true");
            localStorage.setItem("token", data.data.access_token);
            dispatch("profile/GET_PROFILE", null, { root: true }).then(() => {
              resolve(true);
            });
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SET_AUTHORIZE: ({ commit }: { commit: any }) => {
      commit("SET_AUTHORIZE");
    },
    REGISTER_USER: (
      { dispatch, commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("register", data)
          .then(({ data }) => {
            dispatch("profile/GET_PROFILE", null, { root: true }).then(
              ({ type }: { type: string }) => {
                localStorage.setItem("userType", type);
              }
            );
            commit("SET_AUTHORIZE");

            localStorage.setItem("logged", "true");
            localStorage.setItem("token", data.data.access_token);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SEND_OTP: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("register/send_otp", data)
          .then(() => {
            commit("SET_USER", data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    VERIFY_USER: () => {
      return new Promise((resolve, reject) => {
        Api()
          .get("verify")
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    LOGOUT_USER: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("logout")
          .then(() => {
            commit("SET_UNAUTHORIZE");
            commit("profile/SET_PROFILE", {}, { root: true });

            localStorage.removeItem("logged");
            localStorage.removeItem("token");
            localStorage.removeItem("userType");
            localStorage.removeItem("isGuest");
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });
    },
    SOCIAL_LOGIN: (
      { dispatch, commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`social_login/${data.provider}`, data.response)
          .then(({ data }) => {
            localStorage.setItem("logged", "true");
            localStorage.setItem("token", data.data.access_token);
            commit("SET_AUTHORIZE");

            dispatch("profile/GET_PROFILE", null, { root: true }).then(
              ({ type }: { type: string }) => {
                localStorage.setItem("userType", type);
                resolve(true);
              }
            );
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    RESET_PASSWORD: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("reset_password", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
